import React from "react";
 import { StyledProjectDataRow, StyledProjectDataRowTitle, StyledProjectDataRowBody } from './ProjectDataRowStyles'
export const ProjectDataRow=({title, children})=>{
    return(
         
        <StyledProjectDataRow>
            <StyledProjectDataRowTitle>{title}</StyledProjectDataRowTitle>
            <StyledProjectDataRowBody>{children}</StyledProjectDataRowBody>            
        </StyledProjectDataRow>
    
        
        
        
    
    )
}

export default ProjectDataRow;