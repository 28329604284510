import React from 'react';
import Stage from '../../helpers/Stage';
import { CoverStyled } from './styles';

function getDesc(desc){
    return {__html: desc}
}

const ProjectCover=({title, description})=>{
    return(
        <Stage>
            <CoverStyled>
                <div dangerouslySetInnerHTML={getDesc(title.html)}></div>
                <div dangerouslySetInnerHTML={getDesc(description.html)}></div>            
            </CoverStyled>
        </Stage>
    )
}

export default ProjectCover;