import styled from 'styled-components'

export const Container = styled.article`
    width: 100%;
    font-family: 'Proxima Nova';
    color: black;
    display: flex;
    align-items: center;
    flex-flow: column;
    margin-top: 28px;
    margin-bottom: 15px;
    @media screen and (min-width: 768px){
        margin-top: 81px;
        margin-bottom: 30px;
    }
    @media screen and (min-width: 1024px){
        margin-top: 95px;
        margin-bottom: 40px;
    }
    @media screen and (min-width: 1366px){
        margin-top: 134px;
    }
    @media screen and (min-width: 1920px){
        margin-top: 128px;
    }
`
export const Subtitle = styled.h3`
    font-weight: 700;
    font-size: 22px;
    margin-bottom: 20px;
    @media screen and (min-width: 768px){
        margin-bottom: 29px;
        font-size: 28px;
    }
    @media screen and (min-width: 1024px){
        margin-bottom: 31px;
        font-size: 28px;
    }
    @media screen and (min-width: 1366px){
        margin-bottom: 31px;
        font-size: 30px;
    }
    @media screen and (min-width: 1920px){
        margin-bottom: 35px;
        font-size: 39.98px;
    }
`

export const Desc = styled.div`
    font-size: 12.01px;
    font-weight: 500;
    text-align: center;
    line-height: 24.03px;
    width: 291px;
    /* margin: 0 auto;
    margin-top: 28px;
    margin-bottom: 39px; */
    @media screen and (min-width: 768px){
        font-size: 14px;
        line-height: 28px;
        width: 603px;
        /* margin-top: 81px;
        margin-bottom: 121px; */
    }
    @media screen and (min-width: 1024px){
        font-size: 14px;
        line-height: 24px;
        width: 705px;
        /* margin-top: 95px;
        margin-bottom: 97px; */
    }
    @media screen and (min-width: 1366px){
        font-size: 15px;
        line-height: 25px;
        width: 762px;
        /* margin-top: 134px;
        margin-bottom: 92px; */
    }
    @media screen and (min-width: 1920px){
        font-size: 19.99px;
        line-height: 29.99px;
        width: 1026px;
        /* margin-top: 128px;
        margin-bottom: 126px; */
    }
`