import React, { useEffect, useState } from "react"
import { GridColumnsTemplate } from '../components/GridColumnsTemplate'
import { Section } from '../components/Section'
import  BlockParser from '../components/BlockParser'
import Layout from "../components/layout"
import SEO from "../components/seo"
import { useTwScriptReady } from '../hooks/useTwScriptReady'
import 'swiper/swiper.min.css'
import "swiper/swiper.scss"
import "swiper/modules/navigation/navigation.min.css"
import "swiper/modules/pagination/pagination.min.css"

const RedesignTemplate = ({ pageContext }) => {
  
  const [readyTw, readyPin] = useTwScriptReady()
  const blocks = pageContext.item.body1[0].primary.sectionv2.document.data.body; 
  
  const [cargar, setCargar] = useState(true)
  let data = pageContext.item;
  

  let socialItems = []
  
  useEffect(() => {
    if (!readyTw || !readyPin) return
    setCargar(true)

  }, [readyTw, readyPin])
  


  return (
    <Layout>
      <SEO title={data.title.text} description={data.subtitle.text} />
      {
        cargar &&
        <BlockParser blocks={blocks} libsLoaded={cargar}/>
      }
    </Layout>
  )
}

export default RedesignTemplate
