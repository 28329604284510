import styled from "styled-components";

export const StyledCoverFull= styled.div`
    display: flex;
    height: 460px;
    picture{
        width: 100%;
    }
    img{
        height: 460px;
        width: 100%;
        object-fit: cover;
    }
    @media (min-width: 1023px){
        height: 500px;
        max-width: 754px;
        margin: 0 auto;
        img {
            height: 100%;
        }
    }
    @media (min-width: 1359px){
        height: 600px;
        max-width: 1016px;
    }
    @media (min-width: 1919px){
        height: 800px;
        max-width: 1360px;
    }

`