import styled from 'styled-components'
import { fadeIn } from '../../styles/animation'

export const FadedContainer = styled.div`
   position: relative;
   opacity: 0;
   min-height: 150px;
   &.loadedPost{
      ${fadeIn()}
   }
   .instagram_container{
      display: flex;
      justify-content: center;
      iframe{
         min-height: 100% !important;
         margin: 0 !important;
         min-width: 100% !important;
      }
   }

   @media screen and (min-width: 768px) {

   }

   @media screen and (min-width: 1024px) {
      .instagram_container{
         iframe{
            
         }
      }
   }

   @media screen and (min-width: 1366px) {
      
   }

   @media screen and (min-width: 1920px) {

   }
`

export const Container = styled.div`
      position: relative;
      @media screen and (min-width: 768px) {

      }
`
