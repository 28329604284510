import styled from "styled-components";

export const StyledInfoRight=styled.div`
    display: flex;
    position: relative;
    padding-top: 20px;
    flex-direction: column;
    padding-bottom: 90px;
    ul li{
        list-style: square;
    }
    ol li{
        list-style: square;
    }
    &:before{
        content: '';
        width: 100%;
        height: 1px;
        background-color: #000;
        position: absolute;
        top: 0;
    }
    @media screen and (min-width: 1024px){
        flex: 50%;
        flex-direction: row;
    }
`
export const StyledInfoRightLeft=styled.div`
    display: flex;
    @media screen and (min-width: 1024px){
        flex: 50%;
    }
`
export const StyledInfoRightRight=styled.div`
    display: flex;
    flex-direction: column;
    @media screen and (min-width: 1024px){
        flex: 50%;
    }
    p{
        font-family: 'Neue';
        font-size: 18px;
        font-weight: 300;
        color: #000;
        line-height: 30px;

        @media screen and (min-width: 1024px){
            font-size: 16px;
            line-height: 30px;
            padding-right: 15%;
        }
        @media screen and (min-width: 1360px){
            font-size: 18px;
            line-height: 30px;
        }
        @media screen and (min-width: 1560px){
            font-size: 20px;
            line-height: 32px;
        }

        
    }
    
    
`
export const StyledInfoRightTopTitle=styled.div`
    
    h3{
        display: flex;
        font-family: 'Neue';
        font-size: 24px;
        font-weight: 300;
        color: #000;
        
        @media screen and (min-width: 1360px){
            font-size: 30px;
            
        }
        @media screen and (min-width: 1560px){
            font-size: 40px;            
        }


        
    }
    
`
export const StyledInfoRightTopDescription=styled.div`
    display: flex;
    padding-top: 35px;
    
`

export const StyledInfoRightBottomTitle=styled.div`
    display: flex;
    position: relative;
    padding-top: 25px;
    margin-top: 70px;
    &:before{
        content: '';
        width: 100%;
        height: 1px;
        background-color: #000;
        position: absolute;
        top: 0;
    }
    
    h3{
        display: flex;
        font-family: 'Neue';
        font-size: 20px;
        font-weight: 300;
        color: #000;
        @media screen and (min-width: 1024px){
            font-size: 18px;
        }
        @media screen and (min-width: 1360px){
            font-size: 20px;
        }
        @media screen and (min-width: 1560px){
            font-size: 22px;
        }        
    }
`

export const StyledInfoRightBottomDescription=styled.div`
    display: flex;
    flex-direction: column;
    padding-top: 35px;
    `