import React, { useEffect, useState } from "react"
import { Picture } from 'react-responsive-picture'
import { useNearScreen } from '../../../hooks/useNearScreen'

import { useLoader } from '../../../hooks/useLoader'
import { Loader } from '../../loader'
import Stage from "../../helpers/Stage"
import { StyledTwinCover, StyledTwinCoverContainer, StyledTwinCoverLeft, StyledTwinCoverRight } from './styles'

export const TwinCover=({imgLeftDK, imgLeftMB, imgRightDK, imgRightMB, altLeft, altRight })=>{
    const [show, element] = useNearScreen()
    const [loaded, handlerOnLoad, el] = useLoader()
    const [ imgLoaded, setImgLoaded]= useState(false)
    
    

    useEffect(() => {
        
        setImgLoaded(true);
        
      }, [ loaded, element, el ])
    
    return (
      
    
      <StyledTwinCover  ref={element}>
          {show &&
          <StyledTwinCoverContainer>
            <StyledTwinCoverLeft>
              <Picture
              onLoad={() => handlerOnLoad(true)}          
              alt={altLeft}
              sources={[
                {
                  srcSet: imgLeftMB,
                  media: '(max-width: 767px)'
                },
                {
                  srcSet: imgLeftDK,
                  media: '(max-width: 1023px)'
                },
                {
                  srcSet: imgLeftDK,
                  media: '(max-width: 1365px)'
                },
                {
                  srcSet: imgLeftDK,
                  media: '(max-width: 1919px)'
                },
                {
                  srcSet: imgLeftDK
                }
              ]}
              />
            </StyledTwinCoverLeft>
            <StyledTwinCoverRight>
            <Picture
            onLoad={() => handlerOnLoad(true)}          
            alt={altRight}
            sources={[
              {
                srcSet: imgRightMB,
                media: '(max-width: 767px)'
              },
              {
                srcSet: imgRightDK,
                media: '(max-width: 1023px)'
              },
              {
                srcSet: imgRightDK,
                media: '(max-width: 1365px)'
              },
              {
                srcSet: imgRightDK,
                media: '(max-width: 1919px)'
              },
              {
                srcSet: imgRightDK
              }
            ]}
            />
          </StyledTwinCoverRight>
        </StyledTwinCoverContainer>
          }
            { !imgLoaded && <Loader /> }
            
      </StyledTwinCover>
    )
}
export default TwinCover;