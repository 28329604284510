import styled from "styled-components";

export const StyledProjectDataRow= styled.div`
    display: flex;
    padding-top: 10px;
    padding-bottom: 30px;
    flex-direction: column;
    position: relative;
    @media screen and (min-width: 1360px){
        padding-top: 20px;
        padding-bottom: 60px;
    }
    &:before{
        content: '';
        width: 100%;
        height: 1px;
        background-color: #000;
        position: absolute;
        top: 0;
    }
`

export const StyledProjectDataRowTitle= styled.div`
    display: flex;    
    padding-bottom: 10px;
    font-family: 'Neue';
    font-size: 16px;
    font-weight: 300;
    
    @media screen and (min-width: 1360px){
        font-size: 18px;
    }    
    
`
export const StyledProjectDataRowBody= styled.div`
    display: flex;    
    
`