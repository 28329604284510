import styled from "styled-components";

export const StyledTwinCover= styled.div`
    display: flex;    
    margin: 90px 0;
    
    picture{
        width: 100%;
    }
    img{
        height: 338px;
        width: 100%;
        object-fit: cover;
        @media screen and (min-width: 1024px){
            height: auto;
        }
    }
`
export const StyledTwinCoverContainer=styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    @media screen and (min-width: 1024px){
        flex-direction: row;
    }
    
`
export const StyledTwinCoverLeft=styled.div`
    height: 338px;
    @media screen and (min-width: 1024px){
        height: 500px;
    }
    @media screen and (min-width: 1360px){
        height: 700px;
    }
    @media screen and (min-width: 1920px){
        height: 900px;
    }
`
export const StyledTwinCoverRight=styled.div`
    height: 338px;
    @media screen and (min-width: 1024px){
        height: 500px;
    }
    @media screen and (min-width: 1360px){
        height: 700px;
    }
    @media screen and (min-width: 1920px){
        height: 900px;
    }
`