import styled from "styled-components";

export const StyledProjectData= styled.div`
    display: flex;
    margin-top: 90px;
    flex-direction: column;
    margin-bottom: 40px;
`
export const StyledProjectDataTop= styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    

`
export const StyledProjectDataAwards= styled.div`
display: flex;
position: relative;
padding-top: 20px;
padding-bottom: 30px;
flex-wrap: wrap;

`
export const StyledProjectDataBottom= styled.div`
    display: flex;
    position: relative;
    padding-top: 20px;
    padding-bottom: 30px;
    flex-wrap: wrap;
    &:before{
        content: '';
        width: 100%;
        height: 1px;
        background-color: #000;
        position: absolute;
        top: 0;
    }

`

export const StyledProjectDataTitle= styled.div`
    h6{
        display: flex;
        font-family: "Neue";
        font-weight: 300;
        font-size: 16px;
        flex: 1 0 100%;
        padding-bottom: 10px;
    }
    
    
`
export const StyledProjectDataInfoContainer= styled.div`
    display: flex;
    flex-direction: column;
    @media screen and (min-width: 1360px){
        flex-direction: row;
    }
`

export const StyledProjectDataInfoItem= styled.div`
    
    font-family: "Neue";
    font-size: 18px;
    font-weight: 300;
    padding-top: 10px;
    @media screen and (min-width: 1360px){
        flex: 50%;
        padding-right: 5%;
        font-size: 20px;        
    }
`

