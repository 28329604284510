import React from "react";
import { Picture } from 'react-responsive-picture'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from "swiper"

import { StyledSlider } from './styles'

export const Slider= ({items})=>{
    const getSlides=()=>{
        return (
            items.map((item, index)=>{ 
                return (
                    <SwiperSlide key={`key-slide${index}`}>                        
                        <Picture                            
                            alt={item.sliderimage.alt}
                            sources={[
                                {
                                srcSet: item.sliderimage.thumbnails.xs.url,
                                media: '(max-width: 767px)'
                                },
                                {
                                srcSet: item.sliderimage.thumbnails.xs.url,
                                media: '(max-width: 1023px)'
                                },
                                {
                                srcSet: item.sliderimage.thumbnails.xs.url,
                                media: '(max-width: 1365px)'
                                },
                                {
                                srcSet: item.sliderimage.url,
                                media: '(max-width: 1919px)'
                                },
                                {
                                srcSet: item.sliderimage.url
                                }
                            ]}
                        />
                    </SwiperSlide>)
            })
        )
    }
    return(
        <StyledSlider>
            <Swiper
            className="SliderMain"
            pagination={{
                dynamicBullets: true,
                clickable: true,
            }}
            modules={[Pagination]} 
            slidesPerView={1}
            centeredSlides={true}
            spaceBetween={0}
            >                
                {getSlides()}                
            </Swiper>
        </StyledSlider>
    )
}

export default Slider;