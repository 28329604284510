import React, { useEffect, useState } from "react"
import { Picture } from 'react-responsive-picture'
import { useNearScreen } from '../../../hooks/useNearScreen'

import { useLoader } from '../../../hooks/useLoader'
import { Loader } from '../../../components/loader'
import Stage from "../../helpers/Stage"
import { StyledTallCover } from './styles'

export const TallCover=({imgDK, imgMB})=>{
    const [show, element] = useNearScreen()
    const [loaded, handlerOnLoad, el] = useLoader()
    const [ imgLoaded, setImgLoaded]= useState(false)
        

    useEffect(() => {
        
        setImgLoaded(true);
        
      }, [ loaded, element, el ])
    
    return (
      <Stage>
    
      <StyledTallCover  ref={element}>
          {show &&
          <Picture
            onLoad={() => handlerOnLoad(true)}          
            // alt={altImg}
            sources={[
              {
                srcSet: imgMB,
                media: '(max-width: 767px)'
              },
              {
                srcSet: imgDK,
                media: '(max-width: 1023px)'
              },
              {
                srcSet: imgDK,
                media: '(max-width: 1365px)'
              },
              {
                srcSet: imgDK,
                media: '(max-width: 1919px)'
              },
              {
                srcSet: imgDK
              }
            ]}
            />}
            { !imgLoaded && <Loader /> }
      </StyledTallCover>
    </Stage>)
}
export default TallCover;