import React, { useEffect, useRef }from 'react'
import { Container, FadedContainer } from '../FBPost/styles'
// import { useNearScreen } from '../../hooks/useNearScreen'
import { Loader } from '../loader'
import { useLoader } from '../../hooks/useLoader'
// import { useCurrenDevice } from '../../hooks/useCurrentDevice'
import { EmbeddedPost } from 'react-facebook';


export const FBPost =  ({ data, src, _width = '100%', extraClass='', imgClass='', _minHeight, url}) => {

  // const [device] = useCurrenDevice()
  // const [show, element] = useNearScreen()
  const [loaded, el] = useLoader()
  const element = useRef(null)

  useEffect(() => {
    if(!el || !el.current || !element || !element.current) return
    if (loaded) {
    //   // console.log('se cargo este elemento: ', el.current.childNodes)
      el.current.childNodes.forEach((node) => {
        if (node.classList.contains('c-loader')) {
          node.classList.add('hidden')
        }
      })
      element.current.classList.add('loadedPost')
    } else {
      el.current.childNodes.forEach((node) => {
        if (node.classList.contains('c-loader')) {
          node.classList.remove('hidden')
        }
      })
      element.current.classList.remove('loadedPost')
    }
  }, [loaded, el, element])

  // const getPost = (url) =>{
  //   window.FB.api(
  //     "/oembed_post",
  //     {
  //       url: url,
  //       useiframe: true,
  //     },
  //     function (response) {
  //       if (response && !response.error) {
  //         /* handle the result */
  //         console.log("RESPOPNS: ", response)
  //       }
  //     }
  //   );
  // }
      
  return (
    <Container className={extraClass} minHeight={_minHeight} ref={el}>
      <Loader />
      <FadedContainer className='fade__container' ref={element}>
      {
        // show &&
        // <iframe src={`https://www.facebook.com/plugins/post.php?href=${url}`} 
        //         width="100%" height="620px"
        //         scrolling="no"
        //         frameBorder="0"
        //         allow="encrypted-media"
        //         onLoad={ () => handlerOnLoad(true)}>
        // </iframe>
          <EmbeddedPost href={url} width="auto"/>
      }
      </FadedContainer>
    </Container>
  )
}
