import { useState, useEffect } from 'react'

export function useTwScriptReady () {
  const [readyTw, setReadyTw] = useState(false)
  const [readyPin, setReadyPin] = useState(false)
  

 function handleReadyTw(readyTw){
   setReadyTw(readyTw)
  //  console.log('esta lista tw', window.twttr)
 }

  class Deferred {
    constructor() {
      var self = this;
      this.promise = new Promise(function (resolve, reject) {
        self.reject = reject;
        self.resolve = resolve;
      });
    }
  }

  async function getPinScript(d, s, id){
    //Pinterest
    var js, pjs = d.getElementsByTagName(s)[0], p = window.pinjs || {};
    if (d.getElementById(id)) {return p;}
    js = d.createElement(s); 
    js.id = id;
    js.src = "//assets.pinterest.com/js/pinit.js";
    js['data-pin-build'] = "doBuild";
    pjs.parentNode.insertBefore(js, pjs);
    window.pinLoad.resolve()
  }

 async function getScript(d, s, id) {
  ///Twitter
  var js, fjs = d.getElementsByTagName(s)[0],t = window.twttr || {};
  if (d.getElementById(id))return t;
  js = d.createElement(s);
  js.id = id;
  js.src = "https://platform.twitter.com/widgets.js";
  fjs.parentNode.insertBefore(js, fjs);

  t._e = [];
  t.ready = function(f) {
    t._e.push(f);
  };
  t.ready(() => {handleReadyTw(true)} )

  return t;
}

useEffect(() =>{
  async function loadScript() {
    window.pinLoad = (new Deferred())
    await getPinScript(document, 'script', 'pinterest-pjs') 
    window.twttr = await getScript(document, "script", "twitter-wjs");
    window.pinLoad.promise.then(()=>{
      setReadyPin(true)
    })
    // console.log('window.twttr', window.twttr )
    // if(window.twttr.ready){
    //   if(window.twttr.widgets){
    //     loadTweet()
    //   }
    // }
  }

  loadScript()
},[])

  return [readyTw, readyPin]
}
