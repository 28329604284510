import styled from 'styled-components'
import { fadeIn } from '../../styles/animation'

export const Container = styled.div`
   position: relative;
   width: 100%;
   height: 100%;
   /* min-height:100%; */
   min-height: 150px;
   /* height: fit-content; */
   display: flex;
   justify-content: flex-end;
   background: rgba(0,0,0,0.7);
   @media screen and (min-width: 768px){
      /* min-height: 200px; */
   }
   @media screen and (min-width: 1024px){
      /* min-height: 150px; */
      
   }
   @media screen and (min-width: 1366px){
      
   }
   @media screen and (min-width: 1920px){

   }
`

export const FadedContainer = styled.div`
   width: 100%;
   height: 100%;
   opacity: 0;
   .twitter-tweet{
      /* width: 100% !important; */
      /* margin: 0 !important; */
      margin: 0 !important;
      /* display: flex !important;
      justify-content: center !important; */
   }
   &.loadedTw{
      ${fadeIn()}
   }
   @media screen and (min-width: 768px){
      .twitter-tweet{
          width: 100% !important;
      }
   }

   @media screen and (min-width: 1024px){
      .twitter-tweet{
          /* display: block !important; */
      }
   }
`
