import styled from "styled-components";

export const StyledCoverCentered= styled.div`
    display: flex;
    height: 460px;
    @media screen and (min-width: 1024px){
        height: 930px;
    }
    picture{
        width: 100%;
    }
    img{
        height: 460px;
        width: 100%;
        object-fit: cover;
        @media screen and (min-width: 1024px){
            height: 930px;
        }
    }
`