import  styled from 'styled-components';


export const CoverStyled = styled.div`
    
    
    padding: 27px 0 82px;
    @media screen and (min-width: 1360px){
        padding: 60px 0 120px;
    }
    
    h1{
        font-family: 'Neue';
        font-size: 60px;
        font-weight: 300;
        line-height: 80px;        
        em{
            font-family: 'OGG';
            font-style: normal;
        }
        @media screen and (min-width: 768px){
            font-size: 70px;
        }
        @media screen and (min-width: 1024px){
            font-size: 124px;
            line-height: 150px;
            max-width: 768px;
        }
        @media screen and (min-width: 1360px){
            font-size: 164px;
            line-height: 160px;
            max-width: 960px;
        }

        @media screen and (min-width: 1920px){
            font-size: 230px;
            line-height: 200px;
            max-width: 1250px;
            em {
                line-height: 260px;
            }
        }
        
    }
    p{
        margin-top: 68px;
        font-family: 'Neue';
        font-size: 16px;
        font-weight: 300;
        line-height: 28px;
        @media screen and (min-width: 1360px){
            font-size: 18px;
            line-height: 30px;
        }
        @media screen and (min-width: 1360px){
            font-size: 20px;            
            line-height: 32px;            
            padding-left: 50%;
        }
    }
`
