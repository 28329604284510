import styled from 'styled-components'
import { loading } from '../../styles/animation'

export const Container = styled.span`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    /* height: 1; */
    background-color: #f1f1f1;
    opacity: 1;
    &.hidden{
        display: none;
    }
`

export const LogoContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
        ${loading()}
    }
`

