import styled from "styled-components";

export const StyledTallCover= styled.div`
    display: flex;
    height: 640px;
    margin: 90px 0;
    @media screen and (min-width: 1024px){
        height: 1600px;
    }
    picture{
        width: 100%;
    }
    img{
        height: 640px;
        width: 100%;
        object-fit: cover;
        @media screen and (min-width: 1024px){
            height: 1600px;
        }
    }
`