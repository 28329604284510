import React from "react";
import Stage from "../../helpers/Stage";
import { StyledInfoRight, StyledInfoRightLeft, StyledInfoRightRight, StyledInfoRightTopTitle, StyledInfoRightTopDescription, StyledInfoRightBottomTitle, StyledInfoRightBottomDescription } from "./style";

function getDesc(desc){
    return {__html: desc}
}

export const InfoRight = ({titleTop, textTop ,titleBottom, textBottom  })=>{
   
    return(
        <Stage>
            <StyledInfoRight>
            
                <StyledInfoRightLeft>
                    <StyledInfoRightTopTitle dangerouslySetInnerHTML={getDesc(titleTop)}></StyledInfoRightTopTitle>
                </StyledInfoRightLeft>
                <StyledInfoRightRight>
                    <StyledInfoRightTopDescription  dangerouslySetInnerHTML={getDesc(textTop)} ></StyledInfoRightTopDescription>
                    <StyledInfoRightBottomTitle  dangerouslySetInnerHTML={getDesc(titleBottom)} />
                    <StyledInfoRightBottomDescription  dangerouslySetInnerHTML={getDesc(textBottom)} />
                </StyledInfoRightRight>
            
            </StyledInfoRight>
        </Stage>
    )
}

export default InfoRight;