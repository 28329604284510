import styled from "styled-components";

export const StyledMetricItem=styled.div`
    display: flex;
    flex-direction: column;
    
    p{
        font-family: 'Neue';
        font-size: 110px;
        font-weight: 300;
        line-height: 125px;
        @media screen and (min-width: 1024px){
            font-size: 120px;
            line-height: 135px;
        }
        @media screen and (min-width: 1360px){
            font-size: 164px;
            line-height: 185px;
        }
        @media screen and (min-width: 1560px){
            font-size: 230px;
            line-height: 280px;
        }  
        
    }
    margin-bottom: 48px;
    @media screen and (min-width: 1024px){
        flex: 48%;
        max-width: 48%;
    }
    
`

export const StyledMetricItemTitle=styled.div`
    position: relative;
    padding-bottom: 15px;
    h6{
        font-family: 'Neue';
        font-size: 18px;
        font-weight: 300;
        @media screen and (min-width: 1024px){
            font-size: 16px;
        }
        
        @media screen and (min-width: 1560px){
            font-size: 20px;
        }
    }
    &:after{
        content: '';
        width: 100%;
        height: 1px;
        background-color: #000;
        position: absolute;
        bottom: 0;
    }
`
export const StyledMetricItemInfoContainer=styled.div`
    display: flex;
    flex: 100%;
    @media screen and (min-width: 1024px){
        align-items: center;
        justify-content: center;
    }
    
`
export const StyledMetricItemInfoPre=styled.div`

`

export const StyledMetricItemInfo=styled.div`

`

export const StyledMetricItemInfoPos=styled.div`

`