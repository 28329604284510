import styled from 'styled-components'
import { fadeIn } from '../../styles/animation'

export const Container = styled.div`
   position: relative;
   width: 100%;
   min-height: 100px;
   min-width: 100px;
   height: fit-content;

   &.intro__container{
      min-height: 300px;
   }
   &.tweet__post{
      min-height: ${props => props.minHeight};
   }

   @media screen and (min-width: 768px){
      min-height: 200px;

      &.intro__container{
         min-height: 500px;
      }
   }

   @media screen and (min-width: 1024px){

   }

   @media screen and (min-width: 1366px){
      
   }

   @media screen and (min-width: 1920px){

   }
`

export const FadedContainer = styled.div`
   width: 100%;
   height: 100%;
   opacity: 0;

   .styled_image{
    overflow: hidden;
    object-fit: cover;
    width: 100%;
    height: auto;
   }

   &.loadedImage{
      ${fadeIn()}
   }

   .intro__img{
      min-height: 300px;
      height: calc(100vh - 100px);
   }
`
