import React from 'react'
// import MasonryLayout from 'react-masonry-layout'
import Masonry from 'react-masonry-css'
import styled from 'styled-components'
import { FBPost } from '../FBPost'
import { IGPost } from '../IGPost'
import { TWPost } from '../TWPost'
import { PinPost } from '../PinPost'
// import '../masonry/social_bricks'

export const App = styled.div`
 .my-masonry-grid {
    display: -webkit-box; /* Not needed if autoprefixing */
    display: -ms-flexbox; /* Not needed if autoprefixing */
    display: flex;
    width: 100%;
  }
  .my-masonry-grid_column {
    background-clip: padding-box;
  }

  /* Style your items */
  .my-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */
    /* background: grey; */
    margin-bottom: 30px;
  }

  .my-masonry-grid_column div:last-child{
    margin-bottom: 0px;
  }

  @media screen and (min-width: 768px) {
  }

  @media screen and (min-width: 1024px) {
    .my-masonry-grid {
      margin-left: -30px; /* gutter size offset */
      width: calc(100% + 30px);
    }
  
    .my-masonry-grid_column {
      padding-left: 30px; /* gutter size */
    }
  }

  @media screen and (min-width: 1366px) {
  }
  
  @media screen and (min-width: 1920px) {
  }
`

// export const MasonryElement = styled.div`
// `
 
class SocialMasonry extends React.Component {
    // constructor(props){
    //     super(props);
    //     // console.log('props', props);
    // }

    state = {
        // perPage: 10,
        perPage: this.props.socialItems.length,
        items: this.props.socialItems,
        breakpointColumnsObj : {
          default: 3,
          1023: 1
        }
        // items: Array(20).fill()
    }
    
    // loadItems = () => {
    //     this.setState({
    //         items: this.state.items
    //         // items: this.state.items.concat(Array(this.state.perPage).fill())
    //     });
    //     // console.log('imtes dentro masonry ', this.state.items);
    //     // console.log('type ', this.state.items[0].type.name);
    // }

    getSocialObject = (social) => {
      switch(social.type){
        case 'TWPost': return <TWPost className='masonry__element' extraClass='tweet__post' url={social.url} key={social.key}/>
        case 'FBPost': return <FBPost className='masonry__element' extraClass='facebook__post' url={social.url} key={social.key}/>
        case 'IGPost': return <IGPost className='masonry__element' extraClass='instagram__post' url={social.url} key={social.key}/>
        case 'PinPost': return <PinPost libsLoaded={this.props.libsLoaded} className='masonry__element' extraClass='pinteres__post' url={social.url} key={social.key}/>
        default : console.log('is video');break;
      }
    }

  render() {
    return (
      // <App>
      //   <MasonryLayout
      //     id="masonry-layout"
      //     infiniteScroll={this.loadItems}
      //     position={true}
      //     packed= {'data-packed'}
      //     sizes={[{columns: 3, gutter: 10}]}>
      //     {this.state.items.map((social, i) => {
      //       //   console.log('entro aqui')
      //       let height;
      //       if(social.type == 'TWPost'){
      //         height = 600;
      //       }
      //       if(social.type == 'FBPost'){
      //         height = 620;
      //       }
      //       if(social.type == 'IGPost'){
      //         height = 850;
      //       }
      //       // let height = i % 2 === 0 ? 500 : 400;
      //       return (
      //         <div
      //           className= 'masonry__container--element'
      //           key={i}
      //           style={{
      //             width: '30%',
      //             // height: `${height}px`,
      //             height: `auto`,
      //             // lineHeight: `${height}px`,
      //             color: 'white',
      //             fontSize: '32px',
      //             display: 'block',
      //             // background: 'rgba(0,0,0,0.7)'
      //           }}>
      //           {console.log('objeto ', social)}
      //           {/* <img style={{width: '100%', height: '100%'}} src="https://images.pexels.com/photos/3224164/pexels-photo-3224164.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260" alt=""/> */}
      //           {this.getSocialObject(social)}
      //         </div>
      //       )}
      //     )}
 
      //   </MasonryLayout>
      // </App>
      <App>
        <Masonry
          breakpointCols={this.state.breakpointColumnsObj}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column">
          {this.state.items.map((social, i)=>{
            return(
              <div
                className= 'masonry__container--element'
                key={i}>
                  {this.getSocialObject(social)}
              </div>
            )
          })}
        </Masonry>
      </App>
    );
  }
}

export default SocialMasonry;