import React from "react";
import ProjectDataRow from "./ProjectDataRow";
import ProjectDataItem from "./ProjectDataItem";


export const ProjectDataItemsContainer=({items})=>{
return items.map(item=>{
    
    return(
        <ProjectDataRow title={item.datatitle.text}>
            <ProjectDataItem description={item.datacontent}></ProjectDataItem>
        </ProjectDataRow>
    ) 
})
}
export default ProjectDataItemsContainer;