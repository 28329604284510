import React, { useEffect } from 'react'
import ReactPlayer from 'react-player'
// import { useCurrenDevice } from '../../hooks/useCurrentDevice'
import { Container, FadedContainer } from './styles'
import { useNearScreen } from '../../hooks/useNearScreen'
import { Loader } from '../loader'
import { useLoader } from '../../hooks/useLoader'

export const Video = ({ _width = '100%', data, _extClass = '', _height='100%'}) => {
  // const [device] = useCurrenDevice()
  const [show, element] = useNearScreen()
  const [loaded, handlerOnLoad, el] = useLoader()

  useEffect(() => {
    if(!el || !el.current || !element || !element.current) return
    if (loaded) {
    //   // console.log('se cargo este elemento: ', el.current.childNodes)
      el.current.childNodes.forEach((node) => {
        if (node.classList.contains('c-loader')) {
          node.classList.add('hidden')
        }
      })
      element.current.classList.add('loadedVideo')
    } else {
      el.current.childNodes.forEach((node) => {
        if (node.classList.contains('c-loader')) {
          node.classList.remove('hidden')
        }
      })
      element.current.classList.remove('loadedVideo')
    }
  }, [loaded, el, element])

  // useEffect(() => {
  //   handlerOnLoad(false)
  // }, [device])

  // function handlerOnReady (e) {
  //   // console.log('video cargado', e.loaded)
  //   if (e.loaded > 0.90) {
  //     // console.log('video cargado', e.loaded)
  //     // console.log('evento', e)
  //     handlerOnLoad(true)
  //   }
  // }

  function getVideo () {
    // console.log('data en getVideo', data)
    // switch (device) {
    //   case 'desktop':
    //     return <ReactPlayer onProgress={handlerOnReady} className={`fadein__content ${_extClass}`} url={srcLg} playsinline playing loop muted width={_width} height='auto' />
    //   case 'tablet':
    //     return <ReactPlayer onProgress={handlerOnReady} className={`fadein__content ${_extClass}`} url={srcMd} playsinline playing loop muted width={_width} height='auto' />
    //   case 'mobile':
    //     return <ReactPlayer onProgress={handlerOnReady} className={`fadein__content ${_extClass}`} url={srcSm} playsinline playing loop muted width={_width} height='auto' />
    //   case 'mobile_retina':
    //     return <ReactPlayer onProgress={handlerOnReady} className={`fadein__content ${_extClass}`} url={srcSmRetina} playsinline playing loop muted width={_width} height='auto' />
    //   default:
    //     break
    // }
    return <ReactPlayer style={{background:'black'}} onReady={() => handlerOnLoad(true)} className={`fadein__content ${_extClass}`} url={data.url} width='auto' controls playsInline/>
  }
  return (
    <Container ref={el}>
      <FadedContainer ref={element} width={_width}>
        { show && getVideo() }
      </FadedContainer>
      <Loader />
    </Container>
  )
}
