import React, { useEffect, useState } from "react"
import { Picture } from 'react-responsive-picture'
import { useNearScreen } from '../../../hooks/useNearScreen'

import { useLoader } from '../../../hooks/useLoader'
import { Loader } from '../../../components/loader'

import { StyledCoverFull } from "./styles"

export const CoverFull=({imgDK, imgMB})=>{
    const [show, element] = useNearScreen()
    const [loaded, handlerOnLoad, el] = useLoader()
    const [ imgLoaded, setImgLoaded]= useState(false)
    

    useEffect(() => {
        
        setImgLoaded(true);
       
      }, [ loaded, element, el ])

    return(
      <StyledCoverFull ref={element}>
        {show &&
        <Picture
          onLoad={() => handlerOnLoad(true)}          
          // alt={altImg}
          sources={[
            {
              srcSet: imgMB,
              media: '(max-width: 767px)'
            },
            {
              srcSet: imgDK,
              media: '(max-width: 1023px)'
            },
            {
              srcSet: imgDK,
              media: '(max-width: 1365px)'
            },
            {
              srcSet: imgDK,
              media: '(max-width: 1919px)'
            },
            {
              srcSet: imgDK
            }
          ]}
          />}
          { !imgLoaded && <Loader /> }
        
    </StyledCoverFull>)
}

export default CoverFull;