import styled from "styled-components";

export const StyledProjectMetrics=styled.div`
    display: flex;
    margin-top: 25px;
    margin-bottom: 90px;
    flex-direction: column;
`

export const StyledProjectMetricsInfoContainer=styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    padding-top: 25px;
    @media screen and (min-width: 1024px){
        flex-direction: row;
    }
    
    &:before{
        content: '';
        width: 100%;
        height: 1px;
        background-color: #000;
        position: absolute;
        top: 0;
    }

`
export const StyledProjectMetricsTitle=styled.div`
    display: flex;
    
    h5{
        font-family: 'Neue';
        font-size: 18px;
        font-weight: 300;
        @media screen and (min-width: 1024px){
            font-size: 16px;
        }
        
        @media screen and (min-width: 1560px){
            font-size: 20px;
        }
    }
    @media screen and (min-width: 1024px){
        flex: 50%;
    }
`
export const StyledProjectMetricsDescription=styled.div`
    display: flex;
    font-family: 'Neue';
    font-size: 18px;
    font-weight: 300;
    margin-top: 28px;
    line-height: 30px;
    @media screen and (min-width: 1024px){
        flex: 50%;
        font-size: 20px;
        line-height: 33px;
    }
    
`
export const StyledProjectMetricsItemContainer=styled.div`
    display: flex;
    flex-direction: column;
    @media screen and (min-width: 1024px){
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
    }
`