import React, { useEffect, useRef }from 'react'
import { Container, FadedContainer } from './styles'
import { useLoader } from '../../hooks/useLoader'
import { Loader } from '../loader'

export const PinPost =  ({ libsLoaded, data, src, _width = '100%', extraClass='', imgClass='', _minHeight, url}) => {

  // const [show, element] = useNearScreen()
  const [loaded, handlerOnLoad, el] = useLoader()
  const element = useRef(null)

  useEffect(() =>{
    handlerOnLoad(true);
    if(!element || !element.current || !libsLoaded || !window.doBuild) return
    window.doBuild(element.current)
    // window.PinUtils.build();
  }, [element, libsLoaded])

  useEffect(() => {
    if(!el || !el.current || !element || !element.current) return
    if (loaded) {
    // console.log('se cargo este elemento: ', el.current.childNodes)
      el.current.childNodes.forEach((node) => {
        if (node.classList.contains('c-loader')) {
          node.classList.add('hidden')
        }
      })
      element.current.classList.add('loadedPost')
    } else {
      el.current.childNodes.forEach((node) => {
        if (node.classList.contains('c-loader')) {
          node.classList.remove('hidden')
        }
      })
      element.current.classList.remove('loadedPost')
    }
  }, [loaded, el, element])
      
  return (
    <Container className={extraClass} minHeight={_minHeight} ref={el}>
      <Loader />
      <FadedContainer className='fade__container' ref={element}>
      {
        <a className={`my_pinteres_post`} href={url} data-pin-width="large" data-pin-do="embedPin" data-pin-round="true"></a>
      }
      </FadedContainer>
    </Container>
  )
}
