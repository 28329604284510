import React from "react";
import Stage from '../../helpers/Stage';
import ProjectDataRow from './ProjectDataRow';
import ProjectDataItemsContainer from './ProjectDataItemsContainer'
import { ProjectDataAwards } from "./ProjectDataAwards";

import { StyledProjectData, StyledProjectDataBottom, StyledProjectDataTitle, StyledProjectDataInfoContainer, StyledProjectDataInfoItem, StyledProjectDataTop, StyledProjectDataAwards } from './styles'
export const ProjectData=({title, descriptionLeft, descriptionRight, dataItems})=>{
    function getDesc(desc){
        return {__html: desc}
      }
      
      const data= []
      const awards= []
      dataItems.map(item=>{
        
        if(item.hasOwnProperty('projectdataawards')){
            awards.push(item.projectdataawards.document.data.awarddescription.html)
        }
        if(item.hasOwnProperty('projectdataitem')){
            data.push(item.projectdataitem.document.data)
        }
        return 
      })
      
    return(
        <Stage>
            <StyledProjectData>
                
                <StyledProjectDataTop>
                    <ProjectDataItemsContainer items={data}/>
                    <ProjectDataRow title={'Awards'}>
                        <ProjectDataAwards awards={awards}/>
                    </ProjectDataRow>
                </StyledProjectDataTop>
                <StyledProjectDataBottom>
                    <StyledProjectDataTitle><div dangerouslySetInnerHTML={getDesc(title.html)}></div></StyledProjectDataTitle>
                    <StyledProjectDataInfoContainer>
                        <StyledProjectDataInfoItem dangerouslySetInnerHTML={getDesc(descriptionLeft.html)}></StyledProjectDataInfoItem>
                        <StyledProjectDataInfoItem dangerouslySetInnerHTML={getDesc(descriptionRight.html)}></StyledProjectDataInfoItem>
                    </StyledProjectDataInfoContainer>
                </StyledProjectDataBottom>
                
            </StyledProjectData>
        </Stage>
    )
}

export default ProjectData