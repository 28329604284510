import { useState, useRef } from 'react'

export function useLoader () {  
  const [loaded, setLoaded] = useState(false)
  const el = useRef(null)

  function handlerOnLoad (state) {
    // console.log('se cargo el recurso')
    setLoaded(state)
  }
  return [loaded, handlerOnLoad, el]
}
