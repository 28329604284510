import React, { useEffect, useRef }from 'react'
import { Container, FadedContainer } from '../TWPost/styles'
// import { useNearScreen } from '../../hooks/useNearScreen'

import { useLoader } from '../../hooks/useLoader'
import { Loader } from '../loader';

export const TWPost = ({ data, src, _width = '100%', extraClass='', imgClass='', _minHeight, _maxHeight , url}) => {
  // const [show, element] = useNearScreen()
  const [loaded, handlerOnLoad, el] = useLoader()
  const element = useRef(null)
  // const myTweet = useRef(null)
  let tw_id = url.split('/')[5];

  
  const isLoad = () => {
    handlerOnLoad(true)
  }

  const loadTweet = () => {
    // console.log('window', window.twttr)
    window.twttr.widgets.createTweet(tw_id, element.current, {
      conversation : 'none',
      width: '100%'
    })
    .then((el)=>{
      // el.shadowRoot.querySelector(".SandboxRoot").getElementsByClassName('EmbeddedTweet')[0].style.maxWidth = "100%"
      isLoad()
    })
  }

  // const loadScript = new Promise((resolve, reject) => {
  //   const d = document
  //   const s = 'script'
  //   const id = 'twitter-wjs'
  //   try {
  //       var js, fjs = d.getElementsByTagName(s)[0],
  //       t = window.twttr || {};
  //       if (d.getElementById(id)) return t;
  //       js = d.createElement(s);
  //       js.id = id;
  //       js.src = "https://platform.twitter.com/widgets.js";
  //       fjs.parentNode.insertBefore(js, fjs);
      
  //       t._e = [];
  //       t.ready = function(f) {
  //         t._e.push(f);
  //         // resolve(t)
  //       };
  //       resolve(t)
  //       return t;
  //   }
  //   catch (err) {
  //     return null
  //       // console.log("Something went wrong: ", err);
  //   }
  // })
  
  useEffect(()=>{
    // const initTweetApi = async () => {
    //   window.twttr = await loadScript(document, "script", "twitter-wjs")
    //   loadTweet()
    // }
    // initTweetApi()
    // let initTweetApi = new Promise((resolve, reject) => {
    //   const scriptTest = loadScript(document, "script", "twitter-wjs")
    //   console.log('scriptTest', scriptTest)
    //   if(scriptTest === null) return
    //   resolve(scriptTest)
    //   // window.twttr =  loadScript(document, "script", "twitter-wjs")
    // })
    // loadScript
    //   .then(res => {
    //     // window.twttr = res
    //     console.log('res', res.ready())
    //     // loadTweet()
    //   })
     loadTweet()
  },[])

  useEffect(() => {
    if(!el || !el.current || !element || !element.current) return
    if (loaded) {
      
      const listNodes = el.current.childNodes
      listNodes.forEach((node) => {
        
        if (node.classList.contains('c-loader')) {
          node.classList.add('hidden')
        }
        
      })
      el.current.style.background = 'transparent'
      element.current.classList.add('loadedTw')
    } else {
      const listNodes = el.current.childNodes
      listNodes.forEach((node) => {
        
        if (node.classList.contains('c-loader')) {
          node.classList.remove('hidden')
        }
        
      })
      
      element.current.classList.remove('loadedTw')
    }
  }, [ loaded, element, el ])
  
  return (
    <Container ref={el} className={extraClass} minHeight={_minHeight} maxHeight={_maxHeight} >
      <FadedContainer ref={element} width={_width}>
        {
          // show &&

            // <Tweet
            //     ref={myTweet}
            //     tweetId={tw_id}
            //     options={{
            //       width: '100%',
            //     }}
            //     onLoad={() => isLoad()}
            // />
          
        }
      </FadedContainer>
      <Loader />
    </Container>
  )
}
