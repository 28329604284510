import React from 'react'
import { Container, Subtitle, Desc } from './styles'

export const TextRow = ({ subTitule, desc }) => {
  // console.log('data intro', data)

  function getDesc(desc){
    return {__html: desc}
  }

  return (
    <Container>
          <Subtitle>{subTitule}</Subtitle>
          <Desc dangerouslySetInnerHTML={getDesc(desc)}></Desc>
    </Container>
  )
}
