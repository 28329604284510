import React, { useEffect, useRef }from 'react'
import { Container, FadedContainer } from './styled'
import InstagramEmbed from 'react-instagram-embed';
// import { useNearScreen } from '../../hooks/useNearScreen'
import { useLoader } from '../../hooks/useLoader'
import { Loader } from '../loader';


export const IGPost = ({extraClass='', url}) => {
  // const [show, element] = useNearScreen()
  const [loaded, handlerOnLoad, el] = useLoader()
  const element = useRef(null)


  useEffect(() => {
    if(!el || !el.current || !element || !element.current) return
    if (loaded) {
      
      const listNodes = el.current.childNodes
      listNodes.forEach((node) => {
        
        if (node.classList.contains('c-loader')) {
          node.classList.add('hidden')
        }
        
      })
      
      element.current.classList.add('loadedPost')
    } else {
      const listNodes = el.current.childNodes
      listNodes.forEach((node) => {
        
        if (node.classList.contains('c-loader')) {
          node.classList.remove('hidden')
        }
        
      })
      
      element.current.classList.remove('loadedPost')
    }
  }, [ loaded, element, el ])

  return (
    <Container ref={el} className={extraClass}>
      <FadedContainer ref={element}>
        {
          // show &&
            <InstagramEmbed
              clientAccessToken={"301867941176878|9aab870085e9fbd07e94b3ec97c7c400"}
              className='instagram_container'
              url={url}
              hideCaption={false}
              containerTagName='div'
              protocol=''
              injectScript
              onSuccess={() => handlerOnLoad(true)}/>
        }
      </FadedContainer>
      <Loader />
    </Container>
  )
}
