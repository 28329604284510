import { keyframes, css } from 'styled-components'

const fadeInKeyframes = keyframes`
  from {
    /* filter: blur(5px); */
    opacity: 0;
  }

  to {
    /* filter: blur(0); */
    opacity: 1;
  }
`
const LoadingKeyframes = keyframes`
  from {
    /* filter: blur(5px); */
    opacity: 0;
  }

  to {
    /* filter: blur(0); */
    opacity: 1;
  }
`

export const fadeIn = ({ time = '1s', type = 'ease' } = {}) =>
  css`animation: ${fadeInKeyframes} 1s ease;
      animation-fill-mode: forwards;`

export const loading = ({ time = '.5s', type = 'ease' } = {}) =>
  css`animation: ${time} ${LoadingKeyframes} ${type};
      animation-direction: alternate;
      animation-iteration-count: infinite;`
