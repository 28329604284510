import styled from 'styled-components'
import { fadeIn } from '../../styles/animation'

export const FadedContainer = styled.div`
   position: relative;
   opacity: 1;
   /* width: 100%; */
   min-height: 150px;
   /* height: auto; */
   overflow: hidden;
   /* display: none; */
   .fb-post>span {
      /* max-width: 100% !important; */
      width: 100% !important;
   }
   .fb-post iframe {
      /* border: 0 !important; */
      /* display: block !important; */
      /* margin: 0 auto !important; */
      opacity: 0;
      width: calc(100% - 2px) !important;
      height: 100% !important;
      background: white;
      ${fadeIn()}
      /* max-width: 100% !important; */
   }

   @media screen and (min-width: 768px) {
      /* display: initial; */
      /* min-height: 200px; */
   }

   @media screen and (min-width: 1024px) {

   }

   @media screen and (min-width: 1366px) {
      
   }

   @media screen and (min-width: 1920px) {
      
   }
`

export const Container = styled.div`
      position: relative;
      /* width: 100%; */
      /* min-height: 150px; */
      /* height: 100%; */
      /* height: fit-content; */
      .c-loader{
         width: 99%;
      }
      @media screen and (min-width: 768px) {
         /* min-height: 200px; */
      }
`
