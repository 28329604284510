import React from "react";

import { StyledProjectDataAwards, StyledProjectDataAwardsTitle, StyledProjectDataAwardsItem} from './ProjectDataAwardsStyles'
function getDesc(desc){
    return {__html: desc}
  }
const getAwards=(awards)=>{
        
    return awards.map(award=>{
        
        return <StyledProjectDataAwardsItem dangerouslySetInnerHTML={getDesc(award)}></StyledProjectDataAwardsItem>
    })
}

export const ProjectDataAwards=({awards})=>{
    
    return (
        <StyledProjectDataAwards>            
            {awards && getAwards(awards)}
        </StyledProjectDataAwards>
    )
}

export default ProjectDataAwards;