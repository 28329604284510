import styled from "styled-components";

export const StyledProjectDataAwards= styled.div`
    display: flex;
    flex-direction: column;
    padding-top: 10px;
`

export const StyledProjectDataAwardsItem= styled.div`
    font-family: 'Neue';
    font-size: 24px;
    font-weight: 300;
    padding-bottom: 10px;
    
    @media screen and (min-width: 1024px){
        font-size: 22px;
        line-height: 22px;
    }
    @media screen and (min-width: 1360px){
        font-size: 28px;
        line-height: 30px;
    }
    @media screen and (min-width: 1560px){
        font-size: 40px;
        line-height: 40px;
    }
`
