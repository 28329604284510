import styled from 'styled-components'

export const Container = styled.article`
    width: 100%;
    font-family: 'Proxima Nova';
    color: black;
`

export const MarginConteiner = styled.div`
    /* width: 100%; */
    width: 92.1875%;
    margin: 0 auto;
    @media screen and (min-width: 768px) {
        width: 78.9062%;
    }
    @media screen and (min-width: 1024px) {
        width: 91.7968%;
    }
    @media screen and (min-width: 1366px) {
        width: 82.4304%;
    }
    @media screen and (min-width: 1920px) {
        width: 82.03%;
    }
`
export const Slide = styled.div`
    height: 130px;
    /* The image used */
    background-color: #f1f1f1;
    background-image: url(${props => props.src});
    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    @media screen and (min-width: 768px){
        height: 222px;
    }
    @media screen and (min-width: 1024px){
        height: 280px;
    }
    @media screen and (min-width: 1366px){
        height: 334px;
    }
    @media screen and (min-width: 1920px){
        height: 467px;
    }
`
export const SlideShadow = styled.div`
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    top: 0;
    background-color: rgb(28, 28, 28);
    opacity: 0.702;
`
export const SlideTitle = styled.h2`
    font-weight: 800;
    font-size: 28px;
    &.PrismicHomeTwitterBodySection{
        font-size: 25px;
    }
    color: white;
    text-transform: uppercase;
    text-align: center;
    z-index: 50;
    @media screen and (min-width: 768px){
        font-size: 70px;
        &.PrismicHomeTwitterBodySection{
            font-size: 62px;
        }
    }
    @media screen and (min-width: 1024px){
        font-size: 94px;
        &.PrismicHomeTwitterBodySection{
            font-size: 83px;
        }
    }
    @media screen and (min-width: 1366px){
        font-size: 113px;
        &.PrismicHomeTwitterBodySection{
            font-size: 111px;
        }
    }
    @media screen and (min-width: 1920px){
        font-size: 160px;
        &.PrismicHomeTwitterBodySection{
            font-size: 155px;
        }
    }
`

export const Subtitle = styled.h3`
    font-weight: 700;
    font-size: 22px;
    margin-bottom: 20px;
    @media screen and (min-width: 768px){
        margin-bottom: 29px;
        font-size: 28px;
    }
    @media screen and (min-width: 1024px){
        margin-bottom: 31px;
        font-size: 28px;
    }
    @media screen and (min-width: 1366px){
        margin-bottom: 31px;
        font-size: 30px;
    }
    @media screen and (min-width: 1920px){
        margin-bottom: 35px;
        font-size: 39.98px;
    }
`

export const Desc = styled.div`
    font-size: 12.01px;
    line-height: 24.03px;
    font-weight: 500;
    text-align: center;
    width: 291px;
    margin: 0 auto;
    margin-top: 28px;
    margin-bottom: 39px;
    @media screen and (min-width: 768px){
        font-size: 14px;
        line-height: 28px;
        width: 603px;
        margin-top: 81px;
        margin-bottom: 121px;
    }
    @media screen and (min-width: 1024px){
        font-size: 14px;
        line-height: 24px;
        width: 705px;
        margin-top: 95px;
        margin-bottom: 97px;
    }
    @media screen and (min-width: 1366px){
        font-size: 15px;
        line-height: 25px;
        width: 762px;
        margin-top: 134px;
        margin-bottom: 92px;
    }
    @media screen and (min-width: 1920px){
        font-size: 19.99px;
        line-height: 30px;
        width: 1026px;
        margin-top: 128px;
        margin-bottom: 126px;
    }
`

export const Cover = styled.div`
    height: auto;
    display: flex;
    justify-content: center;
    img{
        width: 100%
    }
    margin-bottom: 100px;
`

export const Content = styled.div`
    /* width: 100%; */
    display: grid;
    grid-gap: 20px 0px;
    justify-content: center;
    grid-template-rows: repeat(${props => props.rowsLenght},auto);
    grid-template-columns: 1fr;
    margin-bottom: 30px;

    @media screen and (min-width: 768px){
        
    }

    @media screen and (min-width: 1024px){
        margin-bottom: 100px;
    }

    @media screen and (min-width: 1366px){
        
    }

    @media screen and (min-width: 1920px){
        
    }
`

