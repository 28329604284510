import styled from "styled-components";

export const StyledProjectDataItem= styled.div`
    font-family: 'Neue';
    font-size: 70px;
    font-weight: 300;
    line-height: 70px;
    @media screen and (min-width: 1024px){
        font-size: 110px;
        line-height: 110px;
    }
    @media screen and (min-width: 1360px){
        font-size: 142px;
        line-height: 142px;
    }
    @media screen and (min-width: 1560px){
        font-size: 200px;
        line-height: 200px;
    }

    em{
        font-family: 'OGG';
    }
`
