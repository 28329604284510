import styled from 'styled-components'
import { fadeIn } from '../../styles/animation'

export const FadedContainer = styled.div`
   position: relative;
   opacity: 1;
   /* width: 100%; */
   min-height: 150px;
   /* height: auto; */
   overflow: hidden;
   /* display: none; */
   &>span {
      min-width: 100% !important;
      max-width: 100% !important;
   }
   &.loadedPost{
      ${fadeIn()}
   }

   @media screen and (min-width: 768px) {
      /* display: initial; */
      /* min-height: 200px; */
   }

   @media screen and (min-width: 1024px) {

   }

   @media screen and (min-width: 1366px) {
      
   }

   @media screen and (min-width: 1920px) {
      
   }
`

export const Container = styled.div`
      position: relative;
      /* width: 100%; */
      /* min-height: 150px; */
      /* height: 100%; */
      /* height: fit-content; */
      .c-loader{
         height: 98%;
      }
      @media screen and (min-width: 768px) {
         /* min-height: 200px; */
      }
`
