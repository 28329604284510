import React from "react";
import Stage from "../../helpers/Stage";
import { StyledProjectInfo, StyledProjectInfoTitle, StyledProjectInfoDescription } from "./style";

function getDesc(desc){
    return {__html: desc}
}

export const ProjectInfo = ({title, description  })=>{
   
    return(
        <Stage>
            <StyledProjectInfo>
                <StyledProjectInfoTitle dangerouslySetInnerHTML={getDesc(title)}></StyledProjectInfoTitle>
                <StyledProjectInfoDescription  dangerouslySetInnerHTML={getDesc(description)} ></StyledProjectInfoDescription>
            </StyledProjectInfo>
        </Stage>
    )
}

export default ProjectInfo;