import React from "react";

import { StyledMetricItem, StyledMetricItemTitle, StyledMetricItemInfoContainer, StyledMetricItemInfoPre, StyledMetricItemInfo, StyledMetricItemInfoPos } from "./MetricItemStyles";

function getDesc(desc){
    return {__html: desc}
}

export const MetricItem= ({title, pre, info, pos})=>{
    return(
        <StyledMetricItem>
            <StyledMetricItemTitle dangerouslySetInnerHTML={getDesc(title)}></StyledMetricItemTitle>
            <StyledMetricItemInfoContainer>
                <StyledMetricItemInfoPre dangerouslySetInnerHTML={getDesc(pre)}></StyledMetricItemInfoPre>
                <StyledMetricItemInfo dangerouslySetInnerHTML={getDesc(info)}></StyledMetricItemInfo>
                <StyledMetricItemInfoPos dangerouslySetInnerHTML={getDesc(pos)}></StyledMetricItemInfoPos>
            </StyledMetricItemInfoContainer>
        </StyledMetricItem>
    )
}

export default MetricItem