import React, { useEffect }from 'react'
import { Container, FadedContainer } from './styles'
import { Picture } from 'react-responsive-picture'
import { useNearScreen } from '../../hooks/useNearScreen'
// import { useCurrenDevice } from '../../hooks/useCurrentDevice'
// import { Loader } from '../loader'
// import { isNullOrUndefined } from 'util'
import { useLoader } from '../../hooks/useLoader'
import { Loader } from '../loader'
// const path = require('path')

export const Image = ({ data, src, _width = '100%', extraClass='', imgClass=''}) => {
  const [show, element] = useNearScreen()
  const [loaded, handlerOnLoad, el] = useLoader()
  // const [device] = useCurrenDevice()

  useEffect(() => {
    // if(!loaded) return
    // element.current.classList.add('loadedImage')
    if (loaded) {
      // console.log('se cargo este elemento: ', el.current.childNodes)
      const listNodes = el.current.childNodes
      listNodes.forEach((node) => {
        // console.log('node: ', node)
        if (node.classList.contains('c-loader')) {
          node.classList.add('hidden')
        }
        // console.log('node: ', node)
      })
      // console.log('cargo la imagen ', `${element}  ${loaded}`)
      element.current.classList.add('loadedImage')
    } else {
      const listNodes = el.current.childNodes
      listNodes.forEach((node) => {
        // console.log('node: ', node)
        if (node.classList.contains('c-loader')) {
          node.classList.remove('hidden')
        }
        // console.log('node: ', node)
      })
      // console.log('cambio a false el estado de la imagen cargada', `${loaded}`)
      element.current.classList.remove('loadedImage')
    }
  }, [ loaded, element, el ])

  // useEffect(() => {
  //   handlerOnLoad(false)
  // }, [device])

  // function resolveRetinaMobile () {
  //   if (isNullOrUndefined(srcSmRetina)) {
  //     return path.resolve(__dirname, srcSm)
  //   } else {
  //     return `${path.resolve(__dirname, srcSm)}, ${path.resolve(__dirname, srcSmRetina)} 2x`
  //   }
  // }

  return (
    <Container ref={el} className={extraClass}>
      <FadedContainer ref={element} width={_width}>
        {
          show &&
          <Picture
          onLoad={() => handlerOnLoad(true)}
          className={`styled_image ${imgClass}`}
          // alt={altImg}
          sources={[
            {
              srcSet: !data ? src : data.url,
              media: '(max-width: 767px)'
            },
            {
              srcSet: !data ? src : data.url,
              media: '(max-width: 1023px)'
            },
            {
              srcSet: !data ? src : data.url,
              media: '(max-width: 1365px)'
            },
            {
              srcSet: !data ? src : data.url,
              media: '(max-width: 1919px)'
            },
            {
              srcSet: !data ? src : data.url
            }
          ]}
          />
          // <img alt={altImg} src={src} className={`styled_image ${imgClass}`} onLoad={() => handlerOnLoad(true)} width={imgDimensions.width} height={imgDimensions.height}/>
        }
      </FadedContainer>
      <Loader />
    </Container>
  )
}
