import styled from "styled-components";

export const StyledSlider = styled.div`
    display: flex;
    height: 550px;
    margin: 90px 0;
    /* border: solid red 1px; */

    @media screen and (min-width: 768px){
        height: 600px;
        padding: 0 15px;
    }
    @media screen and (min-width: 1024px){
        height: 640px;
        max-width: 784px;
        margin: 0 auto;
    }
    @media screen and (min-width: 1366px){
        height: 700px;
        max-width: 1046px;
    }
    @media screen and (min-width: 1920px){
        height: 800px;
        max-width: 1360px;
    }
    picture{
        width: 100%;
    }
    img{
        height: calc(100% - 80px);
        width: 100%;
        object-fit: cover;
        @media screen and (min-width: 1024px){
            object-fit: initial;
        }
    }

    .SliderMain {
       position: relative;
        .swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
            position: absolute;
            bottom: 40px;
        .swiper-pagination-bullet {
            width: 25px;
            height: 4px;
            background-color: #736a6a;
            cursor: pointer;
            opacity: 1;
            border-radius: 0px;
        }

        .swiper-pagination-bullet-active{
            width: 25px;
            height: 1px;
            border: solid #000 2px;
            background-color: #000;
            cursor: auto;
            bottom: 0;
        }
    
    }
    }

`