import React from "react";
import { StyledProjectDataItem} from './ProjectDataItemStyles'
const ProjectDataItem =({  description})=>{
    function getDesc(desc){
        return {__html: desc}
      }
      
    return(  
        <StyledProjectDataItem dangerouslySetInnerHTML={getDesc(description.html)}></StyledProjectDataItem>
    )
}
export default ProjectDataItem