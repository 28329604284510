import React from "react";
import MetricItem from "./MetricItem";
import { StyledProjectMetrics, StyledProjectMetricsInfoContainer, StyledProjectMetricsTitle, StyledProjectMetricsDescription, StyledProjectMetricsItemContainer } from "./styles";
import Stage from "../../helpers/Stage";
function getDesc(desc){
    return {__html: desc}
}

export const ProjectMetrics=({title, description, items})=>{
    
    const getITems=(items)=>{
        return items.map((element, index) => {
            return(<MetricItem 
                key={`metric-item-${index}`}
                title={element.metricdata.document.data.metrictitle.html} 
                pre={element.metricdata.document.data.metricpre.html}
                info={element.metricdata.document.data.metricdata.html}
                pos={element.metricdata.document.data.metricpost.html}
                />)
        });
    }
    return(
        <Stage>
            <StyledProjectMetrics>
                <StyledProjectMetricsItemContainer>
                    {items && getITems(items)}
                </StyledProjectMetricsItemContainer>

                <StyledProjectMetricsInfoContainer>
                    <StyledProjectMetricsTitle dangerouslySetInnerHTML={getDesc(title)}></StyledProjectMetricsTitle>
                    <StyledProjectMetricsDescription dangerouslySetInnerHTML={getDesc(description)}></StyledProjectMetricsDescription>
                </StyledProjectMetricsInfoContainer>
            </StyledProjectMetrics>
        </Stage>
    )
    

}

export default ProjectMetrics