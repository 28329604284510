import styled from 'styled-components'

// function test(num, divisor){
//     return Math.round(num/divisor)
// }

export const Container = styled.div`
    width: 100%;
    display: grid;
    grid-auto-columns: 1fr;
    grid-gap: 20px 0px;
    /* grid-gap: 30px 0px; */
    justify-content: center;
    &.row_two_columns{
    }
    &.row_three_columns{
    }

    &.social_masonry_grid{
        display: block;
    }

    @media screen and (min-width: 768px){
        /* grid-gap: 70px 0px; */
        /* margin-bottom: 70px; */
    }

    @media screen and (min-width: 1024px){
        /* margin-bottom: 50px; */
        /* justify-content: space-between; */
        grid-gap: 0px 2%; 
        grid-template-columns: repeat(${props => props.colLength},1fr);
        
        &.row_one_column{
            grid-template-columns: repeat(${props => props.colLength},auto);
        }
    }

    @media screen and (min-width: 1366px){
        /* margin-bottom: 58px; */
        /* justify-content: space-between; */
        /* grid-gap: 0px 58px;  */
        /* grid-template-columns: repeat(${props => props.colLength}, 1fr); */
    }

    @media screen and (min-width: 1920px){
        /* margin-bottom: 100px; */
        /* justify-content: space-between; */
        /* grid-gap: 0px 100px;  */
        /* grid-template-columns: repeat(${props => props.colLength}, 1fr); */
    }

    /* @media screen and (min-width: 768px) {
        grid-template-columns: repeat(${props => test(props.colLength, 2)}, auto);
        grid-template-rows: repeat(2, 1fr);
    } */

    /* @media screen and (min-width: 1024px) {
        grid-template-columns: repeat(${props => props.colLength}, 1fr);
        grid-template-rows: unset;
    } */
`
