import React from 'react';
import ProjectCover from '../Blocks/ProjectCover/index'
import CoverFull from '../Blocks/CoverFull/index'
import ProjectData from '../Blocks/ProjectData'
import CoverCentered  from '../Blocks/CoverCentered';
import Slider from '../Blocks/Slider';
import InfoRight  from '../Blocks/InfoRight';
import TallCover from '../Blocks/TallCover';
import TwinCover from '../Blocks/TwinCover';
import ProjectMetrics from '../Blocks/ProjectMetrics';
import ProjectInfo from '../Blocks/ProjectInfo';
import { TextRow }  from '../TextRow';
import SocialMasonry from '../masonry/social_masonry';
import { Container } from '../GridColumnsTemplate/styles';

import { Image } from '../Image';
import { Video } from '../Video';
import Stage from '../helpers/Stage';

const BlockParser= ({blocks, libsLoaded})=>{
    
    const renderMedia=(item)=>{
        
        const media = [];
        for( let mediaItem in item.primary)
        {
            media.push(item.primary[mediaItem])
        }
        return (
            <Stage>
                <Container colLength = {media.length} className={item.sliceType}>                
                    { media.map((node, key) => { 
                                               
                        switch(node.link_type){
                        case 'Media':                            
                            return <Image data={node} src={node.url} key={`media-image-${key}`} />
                        case 'Web':
                            // console.log('node url', node.url)
                            // console.log('index',node.url.indexOf('https://www.facebook.com'));
                            // _width = '100%', url, _extClass = ''
                            return <Video _extClass={item.sliceType} data={node} key={`media-video-${key}`}/>
                        }
                    })
                    }
                </Container>
            </Stage>
        )
    }
    const blocks_available = {
        'project_cover': (item)=>{
            return (<ProjectCover title={item.primary.covertitle} description={item.primary.coverdescription}/>)
        },
        'img_cover_full':(item)=>{
            return (<CoverFull imgDK={item.primary.coverimage.url} imgMB={item.primary.coverimagexs.url} />)
        },
        'project_data':(item)=>{
            return (<ProjectData title={item.primary.title} 
                descriptionLeft={item.primary.projectdatadescriptionleft} 
                descriptionRight={item.primary.projectdatadescriptionright}
                dataItems={item.items}
                />)
        },
        'cover_centered':(item)=>{
            return(<CoverCentered imgDK={item.primary.coverimage.url} imgMB={item.primary.coverimagexs.url} />)
        },
        'slider':(item)=>{
            return(<Slider items={item.items}/>)
        },
        'project_info_right':(item)=>{
            return (<InfoRight 
                titleTop={item.primary.textrighttitletop.html} 
                textTop={item.primary.textrighttopdescription.html} 
                titleBottom={item.primary.textrightbottomtitle.html} 
                textBottom={item.primary.textrightbottomdescription.html} 
            />)
        },
        'tall_cover_':(item)=>{
            return(<TallCover imgDK={item.primary.tallcoverimage.url} imgMB={item.primary.tallcoverimagexs.url} alt={item.primary.tallcoverimage.alt} />)
        },
        'twin_cover':(item)=>{
            return (<TwinCover 
                imgLeftDK={item.primary.twincoverleft.url} 
                imgLeftMB={item.primary.twincoverleftxs.url} 
                imgRightDK={item.primary.twincoverright.url} 
                imgRightMB={item.primary.twincoverrightxs.url} 
                alt={item.primary.twincoverleft.alt}/>)
        },
        'project_metrics':(item)=>{
            return (<ProjectMetrics 
                title={item.primary.projectmetricsresulttitle.html} 
                description={item.primary.projectmetricsresultdescription.html}
                items={item.items}
                />)
        },
        'project_info':(item)=>{
            return (<ProjectInfo 
                title={item.primary.projectinfotitle.html} 
                description={item.primary.projectinfodescription.html}/>)
        },
        'row_text':(item)=>{
            return (
                <Container colLength = {1} className={'row_text'}>
                    <TextRow subTitule={item.primary.row_text_subtitle.text} desc={item.primary.content.html} />
                </Container>)
        },
        'social_masonry_grid':(item)=>{
            
            const mansoryMedia = []
            item.items.map((node, key)=>{
                if(node.post_link.url.indexOf('https://www.facebook.com')===0){
                let object = {type: 'FBPost', url: node.post_link.url, key: key}
                mansoryMedia.push(object)
                }else if(node.post_link.url.indexOf('https://twitter.com/')===0){
                let object = {type: 'TWPost', url: node.post_link.url, key: key}
                mansoryMedia.push(object)
                }else if(node.post_link.url.indexOf('https://www.instagram.com/')===0){
                let object = {type: 'IGPost', url: node.post_link.url, key: key}
                mansoryMedia.push(object)
                }else if(node.post_link.url.indexOf('https://www.pinterest.')===0){
                // console.log('Entro a pinterest ------------------>', node)
                let object = {type: 'PinPost', url: node.post_link.url, key: key}
                mansoryMedia.push(object)
                }
            })
            
            return (
                <Container key={`cantainer-social-masonry-item-${item.id}`} colLength = {item.items.length} className={item.slice_type}>
                    <SocialMasonry libsLoaded={libsLoaded} socialItems={mansoryMedia}/>
                </Container>
            )
        },
        'row_one_column': (item)=>{
            return renderMedia(item)
        },
        'row_two_columns': (item)=>{
            return renderMedia(item)
        },
        'row_three_columns': (item)=>{
            return renderMedia(item)
        },
        'row_four_columns': (item)=>{
            return renderMedia(item)
        },
        'row_five_columns': (item)=>{
            return renderMedia(item)
        }
        
    };
    
        
    
    return (
        
        blocks.map((item, index)=>{
            
            if(blocks_available.hasOwnProperty(item.slice_type)){  
                return blocks_available[item.slice_type](item);
            }
            else{
                return (<p key={`slice-type-${index}`}>{item.slice_type}</p>)                
            }

        })
        
    )
}

export default BlockParser;