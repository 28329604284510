import React from 'react'
import { Container, LogoContainer } from './styles'
import { LogoW } from '../svg/LogoW'

export const Loader = () => {
  // const [show, element] = useNearScreen()
  // { _width = '100%', srcSm, srcSmRetina, srcMd, srcLg }
  return (
    <Container className='c-loader'>
      <LogoContainer><LogoW width='40px'/></LogoContainer>
    </Container>
  )
}
