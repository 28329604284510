import styled from 'styled-components'
import { fadeIn } from '../../styles/animation'

export const FadedContainer = styled.div`
   position: relative;
   opacity: 0;
   .left__margin{
      margin-left: -1%;
   }
   /* width: 100%; */
   min-height: 150px;
   /* height: auto; */
   overflow: hidden;
   @media screen and (min-width: 768px) {
      min-height: 500px;
      .fadein__content {
         min-height: 500px;
      }
   }

   @media screen and (min-width: 1024px) {
      .row_one_column{
         width: 854px !important;
         height: 480px !important;
      }
      /* .row_two_columns{
         width: 640px !important;
         height: 360px !important;
      } */
      /* .row_three_columns{
         width: 400px !important;
         height: 300px !important;
      } */
   }

   @media screen and (min-width: 1366px) {
      
   }

   @media screen and (min-width: 1920px) {
      
   }

   &.loadedVideo{
      ${fadeIn()}
   }
`

export const Container = styled.div`
      position: relative;
      /* width: 100%; */
      min-height: 150px;
      /* height: 100%; */
      height: fit-content;
      @media screen and (min-width: 768px) {
         min-height: 500px;
      }
`
